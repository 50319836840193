<template>
    <div style="padding: 50px;">
        <div style="width: 1123px;">
            <p class="f-20">欢迎使用花迷婧<span>v3.1</span></p>
            <p>钱包余额：{{ walletBalance }}</p>
            <p>上次登录IP：上次登录时间：{{ loginTime }}</p>
        </div>
        <div>
            <table class="tableUp mt-20">
                <tr>
                    <th colspan="2">
                        营业统计
                    </th>
                </tr>
                <tr v-for="business in businesses" :key="business.id">
                    <td>
                        {{ business.title }}
                    </td>
                    <td>
                        <span>{{ business.num }}</span> 元
                        <a :href="business.url">（点击查看）</a>
                    </td>
                </tr>
            </table>
            <div class="mt-20">
                <p class="flow_p">流量分析</p>
                <el-table :data="flows" border class="cellT" style="box-sizing: content-box;color: black;width: 80%;">
                    <el-table-column prop="target" label="指标">
                    </el-table-column>
                    <el-table-column prop="user" label="用户">
                    </el-table-column>
                    <el-table-column prop="commodity" label="商品">
                    </el-table-column>
                    <el-table-column prop="quantity" label="订单数">
                    </el-table-column>
                    <el-table-column prop="money" label="交易额">
                    </el-table-column>
                    <el-table-column prop="tenant" label="商户">
                    </el-table-column>
                    <el-table-column prop="staff" label="员工">
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            walletBalance: 0,
            loginTime: '2023-2-21',
            UnusedBalance: 8,
            UnusedGold: 0,
            businesses: [
                { id: 1, title: '未使用余额', num: this.UnusedBalance, url: '#' },
                { id: 2, title: '未使用金币', num: this.UnusedGold, url: '#' },
            ],
            flows: [
                {
                    target: '总数',
                    user: '1',
                    commodity: 1,
                    quantity: 0,
                    money: 0,
                    tenant: 0,
                    staff: 0,
                }, {
                    target: '今日',
                    user: '1',
                    commodity: 1,
                    quantity: 0,
                    money: 0,
                    tenant: 0,
                    staff: 0,
                }, {
                    target: '昨日',
                    user: '1',
                    commodity: 1,
                    quantity: 0,
                    money: 0,
                    tenant: 0,
                    staff: 0,
                }, {
                    target: '近30天',
                    user: '1',
                    commodity: 1,
                    quantity: 0,
                    money: 0,
                    tenant: 0,
                    staff: 0,
                }, {
                    target: '日均',
                    user: '1',
                    commodity: 1,
                    quantity: 0,
                    money: 0,
                    tenant: 0,
                    staff: 0,
                }]
        }
    },
    mounted() {
        this.businesses[0].num = this.UnusedBalance;
        this.businesses[1].num = this.UnusedGold;
    },
    computed: {

    },
    methods: {

    },
}
</script>
<style scoped lang="less">
p {
    margin-bottom: 10px;
}

.f-20 {
    font-size: 20px;
}

.mt-20 {
    margin-top: 20px;
}

.tableUp {
    border: 1px solid #DDDDDD;
    border-collapse: collapse;
    border-spacing: 0;
    width: 80%;
    box-sizing: content-box;

    th {
        height: 37px;
        padding: 5px;
        text-align: start;
    }

    td {
        border: 1px solid #DDDDDD;
        padding: 8px;
        height: 37px;
    }
}

.flow_p {
    border: 1px solid #DDDDDD;
    width: calc(80% - 16px);
    height: 37px;
    line-height: 37px;
    margin: 0;
    border-bottom: 0;
    padding: 8px;
    font-weight: bolder;
}
</style>
<style>
.cell {
    text-align: center;
    padding: 8px;
    font-size: 12px;
}



/**
改变边框颜色
 */
.el-table--border,
.el-table--group {
    border: 1px solid #DDDDDD !important;
}

/**
改变表格内竖线颜色
 */
.el-table--border td,
.el-table--border th,
.el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
    border-right: 1px solid #DDDDDD !important;
}

/**
改变表格内行线颜色
 */
.el-table td,
.el-table th.is-leaf {
    border-bottom: 1px solid #DDDDDD !important;
}

.el-table thead tr th {
    border-color: #DDDDDD !important;
}

.el-table thead tr th div {
    font-weight: bolder !important;
    color: #333;
}
</style>
